<template>
  <div>
    <header>
      <h2>Verlanglijst</h2>
      <h6>(laatste update: Vrijdag 13 December 2024)</h6>
      <!-- <nav>
        <ul>
          <li><button :class="[allVisible ? 'activePart' : '']" @click="showAll()">{{ allVisible ? hideText : showText }}</button></li>
          <li><button :class="[legoVisible ? 'activePart' : '']" @click="showLego()">Lego</button></li>
          <li><button :class="[comicsVisible ? 'activePart' : '']" @click="showComics()">Comics</button></li>
          <li><button :class="[recordsVisible ? 'activePart' : '']" @click="showRecords()">Platen</button></li>
          <li><button :class="[sheetmusicVisible ? 'activePart' : '']" @click="showSheetMusic()">Muziek partituren</button></li>
        </ul>
      </nav> -->
    </header>
    <div v-if="allVisible || comicsVisible" class="interestBlock">
      <interest-group 
          :title="'Comics'"
          :groupItems="comicsList">
      </interest-group>
    </div>
    <div v-if="allVisible || recordsVisible" class="interestBlock">
      <interest-group 
          :title="'Vinyl records'"
          :groupItems="recordsList">
      </interest-group>
    </div>
    <!-- <div v-if="allVisible || sheetmusicVisible" class="interestBlock">
      <interest-group 
          :title="'Sheet music'"
          :groupItems="sheetMusicList">
      </interest-group>
    </div> -->
    <!--<div v-if="allVisible || legoVisible" class="interestBlock">
      <interest-group 
          :title="'Lego'"
          :groupItems="legoList">
      </interest-group>
    </div>-->
  </div>
</template>

<script>
import InterestGroup from './interestgroup.vue';

export default {
  components: {
    InterestGroup
  },
  data() {
    return {
      allVisible: true,
      comicsVisible: false,
      // recordsVisible: false,
      // sheetmusicVisible: false,
      legoVisible: false,
      showText: 'Toon alles',
      hideText: 'Verberg alles',
      comicsList: [ 
        { id: 5, name: 'Storm', value: '3, 7 en volgenden' },
        { id: 6, name: 'Thorgal', value: '2 en volgenden' },
        { id: 8, name: 'Yoko Tsuno', value: '1 t.e.m. 4, 6, 7, 11 en volgenden' },
        { id: 1, name: 'Elven', value: '22 en volgenden' }, 
        { id: 2, name: 'Dwergen', value: '9 en volgenden' }, 
        { id: 3, name: 'Orks & Goblins', value: '6 en volgenden' }, 
        { id: 4, name: 'Magiërs', value: '2 en volgenden' },
        { id: 7, name: 'Smurfen', value: '2 tot en met 33, 35 en volgenden' }
      ],
      recordsList: [
        { id: 1, name: 'Brutus - Live in Ghent' },
        { id: 2, name: 'Brutus - Nest' },
        { id: 3, name: 'Amen Ra - Mass V' },
        { id: 4, name: 'Brutus - Burst' },
        { id: 5, name: 'DJ Furax Vs V-Beatz - Other Side (Totalition) {Release nr: DR004}' },
        { id: 6, name: 'Tranceball - Tranceball {Release nr: RM041}' }
      ],
      sheetMusicList: [
        {
          id: 1, 
          name: 'Könemann Music Budapest', 
          value: 'Alles behalve K152 (Favourite Opera Classics VII), K188 (Edvard Grieg - Holberg Suite), K196 (Ludwig van Beethoven - Variationen II) , K201 (Ludwig van Beethoven - Variationen III)',
          items: [
            'Edvard Grieg - Peer Gynt', 'Ludwig van Beethoven - Sonaten I ', 'Ludwig van Beethoven - Sonaten II', 'Ludwig van Beethoven - Sonaten III'
          ]
        },
      ],
      legoList: [
        {
          id: 1, name: 'Classic',
          items: [
            '90 years of play (Nr 11021)',
          ]
        },
        {
          id: 2, name: 'Collectable minifigs Serie 22'
        },
        {
          id: 3, name: 'Baseplates 32x32', value: 'Kleur maakt niet uit'
        },
        {
          id: 4, name: 'Losse lego elementen van de Pick-a-Brick wall.',
          // items: [ 
          //   '2x2 hoge steentjes (eender welke kleur, id 3003)',
          //   '8x16 platen in Bright Green (id 92438)',
          //   '16x16 platen in Bright Green (id 91405)',
          // ]
        },
        {
          id: 5, name: 'Bon/Centjes ter aankoop van grotere sets'
        },
        {
          id: 6, name: 'Speed Champions',
          // items: [ 
          //   'Formula E Panasonic Jaguar Racing GEN2 car & Jaguar I-PACE eTrophy (Nr 76898)',
          //   'Toyota GR Supra (Nr 76901)',
          //   'Koenigsegg Jesko (Nr 76900)',
          //   'Chevrolet Corvette C8.R Race Car and 1968 Chevrolet Corvette (Nr 76903)',
          // ]
        },
        {
          id: 7, name: 'City',
          // items: [
          //   'Cement Mixer Truck (Nr 60325)',
          //   'Rescue Helicopter Transport (Nr 60343)',
          //   'Hospital (Nr 60330)',
          // ]
        },
        {
          id: 8, name: 'Creator Expert',
          // items: [ 
          //   'Assembly Square (Nr 10255)',
          //   'Police Station (Nr 10278)',
          //   'Bookshop (Nr 10270)',
          //   'Boutique Hotel (Nr 10297)',
          //   'Corner Garage (Nr 10264)',
          // ]
        },
        {
          id: 9, name: 'Creator 3-in-1',
          // items: [ 
          //   'Medieval Castle (Nr 31120)',
          //   'Ferris Wheel (Nr 31119)',
          // ]
        },
        {
          id: 10, name: 'Lego Ideas',
          // items: [ 
          //   'Medieval Blacksmith (Nr 21325)',
          //   'Tree House (Nr 21318)',
          // ]
        },
        {
           id: 11, name: 'Technics',
        //   items: [ 
        //     'Lamborhini Sián FKP 37 (Nr 42115)',
        //     'Ferrari 488 GTE "AF Corse #51" (Nr 42125)',
        //     'McLaren Senna GTR (Nr 42123)',
        //     'App-Controlled Cat D11 Bulldozer (Nr 42131)',
        //     'Heavy-duty Tow Truck (Nr 42128)',
        //     'Heavy-duty Excavator (Nr 42121)',
        //     'Liebherr R 9800 Excavator (Nr 42100)',
        //   ]
        },
      ]
    };
  },
  methods: {
    setValues(all, comics, records, sheetmusic, lego)
    {
      this.allVisible = all;
      this.comicsVisible = comics;
      this.recordsVisible = records;
      this.sheetmusicVisible = sheetmusic;
      this.legoVisible = lego;
    },
    showAll() {
      this.setValues(!this.allVisible, false, false, false, false);
    },
    showComics() {
      this.setValues(false, true, false, false, false);
    },
    showRecords() {
      this.setValues(false, false, true, false, false);
    },
    showSheetMusic() {
      this.setValues(false, false, false, true, false);
    },
    showLego() {
      this.setValues(false, false, false, false, true);
    }
  }
}
</script>

<style scoped>
header {
  width: 100%;
  max-height: 10rem;
  background-color:green;
  display: flex;
  justify-content: center;
  align-items: center;
}
h2 {
  margin: 0.5rem;
}

.interestBlock {
  margin: 1rem;
}

nav ul {
  list-style: none;
  /* margin: 0.5rem; */
  /* padding: 0; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0.2 0.5rem;
  padding: 0.1rem 0.2rem;
}

button {
  background-color: transparent;
  text-decoration: none;
  padding: 0.2rem 0.5rem;
  border: 1px solid black;
  color: black;
  border-radius: 5px;
  font-size: large;
}

button:hover {
  background-color: burlywood;
  color: black;
}

.activePart {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
</style>