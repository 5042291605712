<template>
  <div>
    <wishlist></wishlist>
  </div>
</template>

<script>
import Wishlist from './components/wishlist.vue';

export default {
  components: {
    Wishlist
  }
}
</script>

<style>
body {
  background-color: beige;
  margin: 0;
}
</style>