<template>
  <div class="listBlock">
    <h3>{{ title }}</h3>
    <ul v-if="hasData()">
      <li v-for="item in groupItems" :key="item.id">
        <div>{{ item.name }}</div>
        <div class="details" v-if="item.value"> => {{ item.value }}</div>
        <div v-if="item.items">
          <ul v-for="choice in item.items" :key="choice" class="choicesList">
            <li>{{ choice }}</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    groupItems: {
      type: Array,
      required: true,
    }
  },
  methods: {
    hasData() {
      if (this.groupItems)
        return this.groupItems.length > 0;
      else
        return false;
    }
  }
}
</script>

<style scoped>
.listBlock {
  margin: auto;
  width: 90%;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
  justify-items: center;
}

.listBlock h3 {
  margin: auto;
  width: 50%;
}

ul {
  margin: 0.5rem;
}

.details {
  margin: 0.1rem 0.5rem;
  padding: 0;
}

.choicesList {
  list-style: circle;
}

</style>